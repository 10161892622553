import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <Fragment>
            <section className='pb-6'>
                <Container>
                    <Row className='mb-10'>
                        <Col className='mb-4 mb-lg-0' lg={3}>
                            <img src="./../images/logo2.svg" className='mb-3' alt="" />
                            <p className='text__16'>Empower your business, stay competitive, and thrive in your market without technology being a barrier.</p>
                        </Col>
                        <Col className='col-6 mb-4 mb-lg-0' lg={{ span: 2, offset: 1 }}>
                            <h5 className='font-medium text__16 mb-3 text-[#A3A3A3]'>Company</h5>
                            <div className="flex flex-wrap gap-3 font-medium text__16">
                                <NavLink to="/services" className='inline-block w-full text-Mblack'>Services</NavLink>
                                <NavLink to="/about" className='inline-block w-full text-Mblack'>About  Us</NavLink>
                                <NavLink to="/contact" className='inline-block w-full text-Mblack'>Contact Us</NavLink>
                                <NavLink to="/join-team" className='inline-block w-full text-Mblack'>Our team</NavLink>
                            </div>
                        </Col>
                        <Col className='col-6 mb-4 mb-lg-0' lg={2}>
                            <h5 className='font-medium text__16 mb-3 text-[#A3A3A3]'>Link</h5>
                            <div className="flex flex-wrap gap-3 font-medium text__16">
                                <NavLink to="/privacy" className='inline-block w-full text-Mblack'>Privacy Policy</NavLink>
                                <NavLink to="/terms" className='inline-block w-full text-Mblack'>Term & Conditioner</NavLink>
                                <NavLink to="/faq" className='inline-block w-full text-Mblack'>FAQs</NavLink>
                            </div>
                        </Col>
                        <Col className='mb-4 mb-lg-0' lg={4}>
                            <h5 className='font-medium text__16 mb-3'>Newsletter</h5>
                            <div className="flex items-center gap-3">
                                <input type="text" className='w-full h-[46px] border-l-0 border-b-[1px] border-Mblack text__14 outline-none focus:outline-none active:outline-none' placeholder='Enter your email address' />
                                <button className='font-medium text-[14px] bg-Mblue text-Mwhite px-4 py-2 rounded-full'>Submit</button>
                            </div>
                        </Col>
                    </Row>

                    <div className="relative flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between gap-4">
                        <p className='text__16 text-[#A3A3A3]'>© 2023 Edge Up® Marube Labs</p>

                        <div className="flex justify-center items-center gap-3 lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 w-full lg:w-auto">
                            <a href="#!">
                                <img src="./../images/sos (1).svg" alt="" />
                            </a>
                            <a href="#!">
                                <img src="./../images/sos (2).svg" alt="" />
                            </a>
                            <a href="#!">
                                <img src="./../images/sos (3).svg" alt="" />
                            </a>
                            <a href="#!">
                                <img src="./../images/sos (4).svg" alt="" />
                            </a>
                            <a href="#!">
                                <img src="./../images/sos (5).svg" alt="" />
                            </a>
                        </div>

                        <div className="flex items-center gap-3">
                            <NavLink to="/terms" className='font-medium text__16 text-[#525252]'>Terms of Service</NavLink>
                            <NavLink to="/privacy" className='font-medium text__16 text-[#525252]'>Privacy Policy</NavLink>
                        </div>
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default Footer
