import React, { Fragment, useState } from 'react'
import { Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const NewPassword = () => {
    const [statusPassword, setstatusPassword] = useState(false)

    const [tooglePassword, settooglePassword] = useState(true)
    const [tooglePasswordRNew, settooglePasswordRNew] = useState(true)

    const SuccessForm = () => {
        return (
            <Fragment>
                <div className="text-center">
                    <img src="./../../images/tick-circle.svg" className='mb-4 mx-auto' alt="" />
                    <h3 className='font-medium text__32 mb-2'>Success!</h3>
                    <p className='text__16 text-[#636366] mb-10'>Your password is succesfully <br /> created</p>

                    <NavLink to="/" className='inline-block cursor-pointer font-medium text__16 text-Mblue  !border-Mblue hover:text-Mwhite hover:bg-Mblue btnClass'>Continue</NavLink>
                </div>
            </Fragment>
        )
    }

    const NewPasswordForm = () => {
        return (
            <Fragment>
                <img src="./../../images/ghhfgh.svg" className='mb-[2rem]' alt="" />

                <h4 className='font-medium text__24 mb-6'>Create New Password</h4>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Password<span className='text-[#ED544E]'>*</span></Form.Label>
                    <div className="relative">
                        <Form.Control type={tooglePassword ? "password" : "text"} placeholder="***************" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                        <img onClick={() => settooglePassword(!tooglePassword)} src="./../../images/eye-slash.svg" className='cursor-pointer absolute top-1/2 -translate-y-1/2 right-4' alt="" />
                    </div>

                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className='font-normal text__14 text-[#A3A3A3]'>New Password<span className='text-[#ED544E]'>*</span></Form.Label>
                    <div className="relative">
                        <Form.Control type={tooglePasswordRNew ? "password" : "text"} placeholder="***************" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                        <img onClick={() => settooglePasswordRNew(!tooglePasswordRNew)} src="./../../images/eye-slash.svg" className='cursor-pointer absolute top-1/2 -translate-y-1/2 right-4' alt="" />
                    </div>

                </Form.Group>

                <div onClick={()=> setstatusPassword(!statusPassword)} className='cursor-pointer inline-block cursor-pointer text-center font-medium text__16 text-Mwhite !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Next</div>
            </Fragment>
        )
    }
    return (
        <Fragment>
            {
                !statusPassword ? NewPasswordForm() : SuccessForm()
            }
        </Fragment>
    )
}

export default NewPassword
