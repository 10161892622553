import React from 'react'
import Slider from "react-slick";

export const CarouselBrand = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: false,
                    adaptiveHeight: false,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    variableWidth: false,
                    adaptiveHeight: false,
                }
            },
        ]
    };

    const carouselData = [];
    for (let index = 0; index < 2; index++) {

        carouselData.push(<div className="bg-Mwhite p-4 rounded-3xl border border-solid border-[#F5F5F5] w-full lg:!w-[600px]">
            <img src="./../images/sfsf.png" className='mb-3' alt="" />
            <h5 className='text__16'>I never knew how much time I was wasting on manual tasks until EdgeUp automated my processes. Their integration of AI tools has transformed my e-commerce business, and I'm seeing the growth to prove it.</h5>
            <div className="flex items-center gap-2 my-3">
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
            </div>
            <div className="flex items-center gap-2">
                <img src="./../images/av (5).png" className='w-[48px] h-[48px] rounded-full object-cover' alt="" />
                <div>
                    <h5 className='font-medium text__18 mb-1'>Sophia Rodriguez</h5>
                    <p className='text__14 text-[#525252]'>GlowUp LLC</p>
                </div>
            </div>
        </div>)

        carouselData.push(<div className="bg-Mwhite p-4 rounded-3xl border border-solid border-[#F5F5F5] w-full lg:!w-[600px]">
            <img src="./../images/sfsf.png" className='mb-3' alt="" />
            <h5 className='text__16'>Their full-stack development team is top-notch! They took my concept and turned it into a fully functional app that's user-friendly and scalable. The attention to detail and project management was impressive.</h5>
            <div className="flex items-center gap-2 my-3">
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
            </div>
            <div className="flex items-center gap-2">
                <img src="./../images/av (2).png" className='w-[48px] h-[48px] rounded-full object-cover' alt="" />
                <div>
                    <h5 className='font-medium text__18 mb-1'>Marcus DuBois</h5>
                    <p className='text__14 text-[#525252]'>Small Buisness Owner</p>
                </div>
            </div>
            </div>)

        carouselData.push(<div className="bg-Mwhite p-4 rounded-3xl border border-solid border-[#F5F5F5] w-full lg:!w-[600px]">
            <img src="./../images/sfsf.png" className='mb-3' alt="" />
            <h5 className='text__16'>Working with Edge Up has been a breath of fresh air. It'slike having my own IT department. Their ability to tailor tech solutions to my unique small business needs has not only improved efficiency but has also driven increased sales.</h5>
            <div className="flex items-center gap-2 my-3">
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
                <img src="./../images/star (1).svg" alt="" />
            </div>
            <div className="flex items-center gap-2">
                <img src="./../images/av (3).png" className='w-[48px] h-[48px] rounded-full object-cover' alt="" />
                <div>
                    <h5 className='font-medium text__18 mb-1'>Oliver Joseph</h5>
                    <p className='text__14 text-[#525252]'>Independent Entreppreneur</p>
                </div>
            </div>
            </div>)
            }

    return (
        <div className="w-[92%] mx-auto md:w-full md:mx-auto">
            <Slider {...settings} className='-mx-[15px] dotBold'>
                {
                    carouselData.map((obj) => {
                        return <div className="px-[15px]">{obj}</div>
                    })
                }

            </Slider>
        </div>
    )
}
