import React, { Fragment, useState } from 'react'
import { Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const Login = () => {
    const [toogleTab, settoogleTab] = useState("Log In")
    const [tooglePassword, settooglePassword] = useState(true)
    const [tooglePasswordRegister, settooglePasswordRegister] = useState(true)
    const [toogleChecklist, settoogleChecklist] = useState(false)

    const loginForm = () => {
        return (
            <Fragment>
                <h4 className='font-medium text__24 mb-3'>Hey There, Welcome Back!</h4>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Email<span className='text-[#ED544E]'>*</span></Form.Label>
                    <Form.Control type="email" placeholder="E.g; james@company.com" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Password<span className='text-[#ED544E]'>*</span></Form.Label>
                    <div className="relative">
                        <Form.Control type={tooglePassword ? "password" : "text"} placeholder="***************" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                        <img onClick={() => settooglePassword(!tooglePassword)} src="./../../images/eye-slash.svg" className='cursor-pointer absolute top-1/2 -translate-y-1/2 right-4' alt="" />
                    </div>

                </Form.Group>

                <div className="mb-3 flex items-center gap-2 cursor-pointer" onClick={() => settoogleChecklist(!toogleChecklist)}>
                    <div className={"flex items-center justify-center w-[24px] h-[24px] rounded-full border border-solid  " + (toogleChecklist ? "border-Mblue bg-Mblue" : "border-[#A3A3A3]")}>
                        <img src="./../images/check (3).svg" className={toogleChecklist ? "opacity-100" : "opacity-0"} alt="" />
                    </div>
                    <span className='text__14'>Keep me logged in on this device</span>
                </div>

                <div className="text-center">
                    <NavLink to="/" className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Log In</NavLink>

                    <NavLink to="/auth/forgot-password" className='inline-block font-medium text__16 mt-3 text-Mblue'>I forgot my password</NavLink>

                    <div className="my-3 relative">
                        <div className="absolute h-[1px] left-0 w-full top-1/2 -translate-y-1/2 bg-[#F5F5F5]"></div>
                        <div className='px-4 py-2 bg-Mwhite relative inline-block text__12 text-[#525252] relative z-[2]'>Or Log In with</div>
                    </div>

                    <div className="grid grid-cols-1 grid-rows-2 gap-3">
                        <a href='#!' className="relative inline-block px-3 py-[10px] w-full text-center rounded-[50px] border border-solid border-[#F5F5F5] ">
                            <img src="./../images/Icon - Google.svg" className='absolute left-[10px] top-1/2 -translate-y-1/2' alt="" />
                            <div className="relative z-2 font-medium text__14">
                                Google
                            </div>
                        </a>
                        <a href='#!' className="relative inline-block px-3 py-[10px] w-full text-center rounded-[50px] border border-solid border-[#F5F5F5] ">
                            <img src="./../images/facebook-3-2 1.svg" className='absolute left-[10px] top-1/2 -translate-y-1/2' alt="" />
                            <div className="relative z-2 font-medium text__14">
                                Facebook
                            </div>
                        </a>
                    </div>
                </div>

            </Fragment>
        )
    }

    const signupForm = () => {
        return <Fragment>
            <h4 className='font-medium text__24 mb-3'>Create an account</h4>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Full name<span className='text-[#ED544E]'>*</span></Form.Label>
                <Form.Control type="email" placeholder="Full name" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Email<span className='text-[#ED544E]'>*</span></Form.Label>
                <Form.Control type="email" placeholder="E.g; james@company.com" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Password<span className='text-[#ED544E]'>*</span></Form.Label>
                <div className="relative">
                    <Form.Control type={tooglePasswordRegister ? "password" : "text"} placeholder="***************" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                    <img onClick={() => settooglePasswordRegister(!tooglePasswordRegister)} src="./../../images/eye-slash.svg" className='cursor-pointer absolute top-1/2 -translate-y-1/2 right-4' alt="" />
                </div>

            </Form.Group>

            <div className="mb-3 flex items-center gap-2 cursor-pointer" onClick={() => settoogleChecklist(!toogleChecklist)}>
                <div className={"flex items-center justify-center w-[24px] h-[24px] rounded-full border border-solid  " + (toogleChecklist ? "border-Mblue bg-Mblue" : "border-[#A3A3A3]")}>
                    <img src="./../images/check (3).svg" className={toogleChecklist ? "opacity-100" : "opacity-0"} alt="" />
                </div>
                <span className='text__14'>Keep me logged in on this device</span>
            </div>

            <div className="text-center">
                <NavLink to="/" className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Sign Up</NavLink>

                <div className="my-3 relative">
                    <div className="absolute h-[1px] left-0 w-full top-1/2 -translate-y-1/2 bg-[#F5F5F5]"></div>
                    <div className='px-4 py-2 bg-Mwhite relative inline-block text__12 text-[#525252] relative z-[2]'>Or Sign Up with</div>
                </div>

                <div className="grid grid-cols-1 grid-rows-2 gap-3">
                    <a href='#!' className="relative inline-block px-3 py-[10px] w-full text-center rounded-[50px] border border-solid border-[#F5F5F5] ">
                        <img src="./../images/Icon - Google.svg" className='absolute left-[10px] top-1/2 -translate-y-1/2' alt="" />
                        <div className="relative z-2 font-medium text__14">
                            Google
                        </div>
                    </a>
                    <a href='#!' className="relative inline-block px-3 py-[10px] w-full text-center rounded-[50px] border border-solid border-[#F5F5F5] ">
                        <img src="./../images/facebook-3-2 1.svg" className='absolute left-[10px] top-1/2 -translate-y-1/2' alt="" />
                        <div className="relative z-2 font-medium text__14">
                            Facebook
                        </div>
                    </a>
                </div>
            </div>

        </Fragment>
    }
    return (
        <Fragment>
            <img src="./../../images/ghhfgh.svg" alt="" />

            <div className="grid grid-cols-2 grid-rows-1 text-center my-3">
                <div onClick={() => settoogleTab("Log In")} className={"font-medium text__20  py-3 cursor-pointer border-b-[2px] border-solid  " + (toogleTab == "Log In" ? "text-Mblue !border-Mblue" : "text-[#A3A3A3] !border-[#A3A3A3]")}>
                    Log In
                </div>
                <div onClick={() => settoogleTab("Create account")} className={"font-medium text__20 py-3 cursor-pointer border-b-[2px] border-solid " + (toogleTab == "Create account" ? "text-Mblue !border-Mblue" : "text-[#A3A3A3] !border-[#A3A3A3]")}>
                    Create account
                </div>
            </div>

            {
                toogleTab == "Log In" ? loginForm() : signupForm()
            }
        </Fragment>
    )
}

export default Login
