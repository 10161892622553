import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import { CardBlog } from '../Components/Card/Card'
import GetStarted from '../Components/Pages/GetStarted'

const Blog = () => {
    const dataBlog = {
        large: [
            {
                img: "./../images/blg (6).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "Unlocking the Secrets of Successful Email Copywriting",
                type: "large"
            },
            {
                img: "./../images/blg (7).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "5 Tips for Writing Compelling Email Subject Lines",
                type: "large"
            },
        ],
        small: [
            {
                img: "./../images/blg (8).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "Optimizing Your Email Sender Reputation for Better Deliverability",
                type: "small"
            },
            {
                img: "./../images/blg (1).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "How to Create Personalized Email Campaigns",
                type: "small"
            },
            {
                img: "./../images/blg (2).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "5 Tips for Writing Compelling Email Subject Lines",
                type: "small"
            },
            {
                img: "./../images/blg (3).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "Streamlining Your Marketing Efforts",
                type: "small"
            },
            {
                img: "./../images/blg (4).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "Building a Strong Email Subscriber List",
                type: "small"
            },
            {
                img: "./../images/blg (5).jpg",
                category: "Email Marketing",
                by: "Zaire Dorwart",
                title: "5 Tips for Writing Compelling Email Subject Lines",
                type: "small"
            },
        ]
    }
    return (
        <Fragment>
            <HeadTitle text={"News, tips and resources <br class='sm:block hidden' /> about GrowUp"} />

            <section className='pb-0 md:!pb-[80px]'>
                <Container>
                    <Row>
                        {
                            dataBlog.large.map((obj) => {
                                return <Col className='mb-4 md:mb-0' md={6}><CardBlog data={obj} /></Col>
                            })
                        }
                    </Row>
                </Container>
            </section>

            <section className='pt-0 md:!pt-[80px]'>
                <Container>
                    <div className="flex items-center justify-between mb-10">
                        <h2 className='font-medium text-[18px] sm:text-[24px] md:text-[28px] lg:text-[32px] xl:text-[48px] shrink-0'>Lets grow together</h2>
                        <div className="flex items-center gap-2">
                            <p className='text__16 text-[#737373] hidden md:block'>Categories</p>
                            <Form.Select aria-label="Default select example" className='font-medium text__16  h-[45px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#E5E5E5] focus:border-[#E5E5E5] shrink-0 w-auto  focus:!pr-4 min-w-[160px] md:!min-w-[190px]'>
                                <option>Email Marketing</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </div>
                    </div>

                    <Row>
                        {
                            dataBlog.small.map((obj) => {
                                return <Col md={6} lg={4} className='mb-4'><CardBlog data={obj} /></Col>
                            })
                        }
                    </Row>

                    <div className="mt-6 text-center">
                        <Pagination className='justify-center paginationnCustom'>
                            <Pagination.First />
                            <Pagination.Prev />
                            <Pagination.Item>{1}</Pagination.Item>
                            <Pagination.Ellipsis />

                            <Pagination.Item>{10}</Pagination.Item>
                            <Pagination.Item>{11}</Pagination.Item>
                            <Pagination.Item active>{12}</Pagination.Item>
                            <Pagination.Item>{13}</Pagination.Item>
                            <Pagination.Item>{14}</Pagination.Item>

                            <Pagination.Ellipsis />
                            <Pagination.Item>{20}</Pagination.Item>
                            <Pagination.Next />
                            <Pagination.Last />
                        </Pagination>
                    </div>
                </Container>
            </section>

            <GetStarted />
        </Fragment>
    )
}

export default Blog
