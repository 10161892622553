import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import { NavLink } from 'react-router-dom'

const JoinTeam = () => {
    return (
        <Fragment>
            <HeadTitle text={"Want to join us on this <br class='sm:block hidden' /> mission?"} />

            <section>
                <Container>
                    <Row>
                        <Col className='mb-4 md:mb-0' md={6}>
                            <NavLink to="/join-form" className="w-full inline-block p-4 rounded-[16px] border border-solid border-[#F5F5F5]">
                                <h4 className='font-medium text__24 mb-3'>Sr. Software Engineer</h4>
                                <div className="flex items-center gap-3 mb-3">
                                    <div className="flex items-center gap-2">
                                        <img src="./../images/profile-2user.svg" alt="" />
                                        <span className='text__14 text-[#525252]'>1-3 Year</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <img src="./../images/timer.svg" alt="" />
                                        <span className='text__14 text-[#525252]'>Remote</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <img src="./../images/location.svg" alt="" />
                                        <span className='text__14 text-[#525252]'>Remote</span>
                                    </div>
                                </div>
                                <p className='text__16 mb-3'>We are seeking a passionate and skilled Ruby on Rails, backend engineer to join our dynamic team at ConvertKit. You'll play a crucial role in crafting and maintaining ConvertKit's core application logic. From email marketing to visual automation and API products, you'll have the opportunity to contribute to the development of some of our most challenging platform aspects.</p>

                                <div className="flex items-center gap-2 mb-3 flex-wrap">
                                    <div className="px-3 py-2 bg-[#FAFAFA] rounded-[20px]">Frond-end</div>
                                    <div className="px-3 py-2 bg-[#FAFAFA] rounded-[20px]">Developer</div>
                                    <div className="px-3 py-2 bg-[#FAFAFA] rounded-[20px]">Programming</div>
                                </div>

                                <h5 className='font-medium text__20'>$620K<span className='text__16 text-[#525252] font-normal'>/Years</span></h5>
                            </NavLink>
                        </Col>
                        <Col className='mb-4 md:mb-0' md={6}>
                            <NavLink to="/join-form" className="w-full inline-block p-4 rounded-[16px] border border-solid border-[#F5F5F5]">
                                <h4 className='font-medium text__24 mb-3'>Sr. UI/UX Web Designer</h4>
                                <div className="flex items-center gap-3 mb-3">
                                    <div className="flex items-center gap-2">
                                        <img src="./../images/profile-2user.svg" alt="" />
                                        <span className='text__14 text-[#525252]'>App Design</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <img src="./../images/timer.svg" alt="" />
                                        <span className='text__14 text-[#525252]'>Web Design</span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <img src="./../images/location.svg" alt="" />
                                        <span className='text__14 text-[#525252]'>Interaction Design</span>
                                    </div>
                                </div>
                                <p className='text__16 mb-3'>We are seeking a passionate and skilled Ruby on Rails, backend engineer to join our dynamic team at ConvertKit. You'll play a crucial role in crafting and maintaining ConvertKit's core application logic. From email marketing to visual automation and API products, you'll have the opportunity to contribute to the development of some of our most challenging platform aspects.</p>

                                <div className="flex items-center gap-2 mb-3 flex-wrap">
                                    <div className="px-3 py-2 bg-[#FAFAFA] rounded-[20px]">Frond-end</div>
                                    <div className="px-3 py-2 bg-[#FAFAFA] rounded-[20px]">Developer</div>
                                    <div className="px-3 py-2 bg-[#FAFAFA] rounded-[20px]">Programming</div>
                                </div>

                                <h5 className='font-medium text__20'>$420K<span className='text__16 text-[#525252] font-normal'>/Years</span></h5>
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </section>



            <section>
                <Container>
                    <div className="mb-10">
                        <h2 className='font-medium text__48 mb-2'>The benefits we offer</h2>
                        <p className='text__16'>Aside from working with smart people on an important mission, when you <br /> join the ConvertKit team full time you’ll also get these great benefits.</p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-3 gap-4">
                        <div className="flex items-center w-full gap-3 p-6 rounded-[24px] bg-[#FAFAFA]">
                            <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                <img src="./../images/airplane.svg" alt="" />
                            </div>
                            <div className="">
                                <h5 className='font-medium text__24 mb-2'>Vacation time</h5>
                                <p className='text__18'>3 weeks of paid vacation a year. After a year with the team, your paid vacation increases to 4 weeks.</p>
                            </div>
                        </div>
                        <div className="flex items-center w-full gap-3 p-6 rounded-[24px] bg-[#FAFAFA]">
                            <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                <img src="./../images/health.svg" alt="" />
                            </div>
                            <div className="">
                                <h5 className='font-medium text__24 mb-2'>Health</h5>
                                <p className='text__18'>Excellent health, vision, and dental benefits. 2 weeks of paid sick leave per year, including mental health days.</p>
                            </div>
                        </div>
                        <div className="flex items-center w-full gap-3 p-6 rounded-[24px] bg-[#FAFAFA]">
                            <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                <img src="./../images/devices.svg" alt="" />
                            </div>
                            <div className="">
                                <h5 className='font-medium text__24 mb-2'>Equipment</h5>
                                <p className='text__18'>US-based employees get a $3,200 equipment allowance on their first day and $2,500 every 2 years after that.</p>
                            </div>
                        </div>
                        <div className="flex items-center w-full gap-3 p-6 rounded-[24px] bg-[#FAFAFA]">
                            <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                <img src="./../images/favorite-.svg" alt="" />
                            </div>
                            <div className="">
                                <h5 className='font-medium text__24 mb-2'>Profit sharing bonus</h5>
                                <p className='text__18'>We celebrate our hard work and success with twice yearly profit sharing distributions.</p>
                            </div>
                        </div>
                        <div className="flex items-center w-full gap-3 p-6 rounded-[24px] bg-[#FAFAFA]">
                            <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                <img src="./../images/profile-.svg" alt="" />
                            </div>
                            <div className="">
                                <h5 className='font-medium text__24 mb-2'>Team retreats</h5>
                                <p className='text__18'>The team gathers twice a year in-person for fantastic team retreats where we strategize, connect and bond.</p>
                            </div>
                        </div>
                        <div className="flex items-center w-full gap-3 p-6 rounded-[24px] bg-[#FAFAFA]">
                            <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                <img src="./../images/lovely.svg" alt="" />
                            </div>
                            <div className="">
                                <h5 className='font-medium text__24 mb-2'>Supportive leave</h5>
                                <p className='text__18'>We’re here to support you when life takes a turn. You’ll have up to 6 weeks (each) of paid bereavement leave.</p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default JoinTeam
