import React, { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import ContactForm from '../Components/Pages/ContactForm'

const Faq = () => {
    const FaqData = [
        {
            title: "How is the pricing structure for your SaaS email marketing platform determined?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Are there any additional costs or hidden fees associated with your SaaS email marketing platform?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Can I customize my pricing plan based on my specific requirements?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Do you offer a free trial or a demo of your SaaS email marketing platform?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Are there any long-term contracts or can I cancel at any time?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "How can I measure the success of my email marketing campaigns?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "How can I improve email deliverability?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "How can I increase email open rates and click-through rates?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Is there a best time to send emails?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "How can I reduce unsubscribes and spam complaints?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
    ]

    const [toogleAccordion, settoogleAccordion] = useState(-1)

    const toogelAccordionWrap = (e) => {
        if (e == toogleAccordion) {
            settoogleAccordion(-1)
        } else {
            settoogleAccordion(e)
        }
    }
    return (
        <Fragment>
            <HeadTitle text={"Frequently asked questions"} sub={"If you have questions that are not listed here, send them to us via <span style='color:#12A5BC'>email.</span>"} />

            <section className='pb-0 md:!pb-[80px]'>
                <Container>
                    {
                        FaqData.map((obj, i) => {
                            return <Fragment>
                                <div>
                                    <div className="py-3 flex justify-between items-center cursor-pointer " onClick={() => toogelAccordionWrap(i)}>
                                        <h5 className='font-medium text__24'>{obj.title}</h5>
                                        <div className={"arrowPlus relative w-[24px] h-[24px] " + (toogleAccordion == i ? "active" : "")}>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>

                                    <div className={toogleAccordion == i ? "block" : "hidden"}>
                                        <p className='text__18'>{obj.desc}</p>
                                    </div>
                                </div>
                                <hr className='my-3 !border-[#E5E5E5]' />
                            </Fragment>
                        })
                    }
                </Container>
            </section>

            <ContactForm />
        </Fragment>
    )
}

export default Faq
