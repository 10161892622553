import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const YouDo = () => {
    return (
        <section>
            <Container>
                <div className="text-center mb-10">
                    <h2 className='font-medium text__48 mb-2'>Enhance Your Operations </h2>
                    <p className='text__16'>Unlock the potential of AI and automation to streamline your business processes efficiently <br className='hidden sm:block' /> and effectively.</p>
                </div>

                <Row>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/favorite-chart.svg" alt="" />
                            </div>

                            <h4 className='font-medium text__24 mb-3'>Grow Your Business</h4>
                            <p className='text__16'>Deploy our tech solutions to automate routine tasks, allowing you to focus on growth and innovation.</p>
                        </div>
                    </Col>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/graph.svg" alt="" />
                            </div>

                            <h4 className='font-medium text__24 mb-3'>Automate Your Operations</h4>
                            <p className='text__16'>Harness the power of AI-driven tools to optimize your workflows, reduce waste, and increase efficiency.</p>
                        </div>
                    </Col>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4}>
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mblue mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/3dcube.svg" alt="" />
                            </div>

                            <h4 className='font-medium text__24 mb-3'>Innovate Continuously</h4>
                            <p className='text__16'>Utilize our expertise to implement cutting-edge technologies that keep you ahead in a dynamic market.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default YouDo
