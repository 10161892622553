import React from 'react'

export const SearchIcon = ({ width = 24, height = 24, color = "#171717" }) => {
    return (
        <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.739 2C17.109 2 21.477 6.368 21.477 11.738C21.477 14.2715 20.5047 16.5823 18.9136 18.3165L22.0444 21.4407C22.3374 21.7337 22.3384 22.2077 22.0454 22.5007C21.8994 22.6487 21.7064 22.7217 21.5144 22.7217C21.3234 22.7217 21.1314 22.6487 20.9844 22.5027L17.8159 19.343C16.1491 20.6778 14.0357 21.477 11.739 21.477C6.369 21.477 2 17.108 2 11.738C2 6.368 6.369 2 11.739 2ZM11.739 3.5C7.196 3.5 3.5 7.195 3.5 11.738C3.5 16.281 7.196 19.977 11.739 19.977C16.281 19.977 19.977 16.281 19.977 11.738C19.977 7.195 16.281 3.5 11.739 3.5Z" fill={color} />
        </svg>
    )
}
export const BagIcon = ({ width = 24, height = 24, color = "#525252" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9597 8.96002C19.2897 8.22002 18.2797 7.79002 16.8797 7.64002V6.88002C16.8797 5.51002 16.2997 4.19002 15.2797 3.27002C14.2497 2.33002 12.9097 1.89002 11.5197 2.02002C9.12975 2.25002 7.11975 4.56002 7.11975 7.06002V7.64002C5.71975 7.79002 4.70975 8.22002 4.03975 8.96002C3.06975 10.04 3.09975 11.48 3.20975 12.48L3.90975 18.05C4.11975 20 4.90975 22 9.20975 22H14.7897C19.0897 22 19.8797 20 20.0897 18.06L20.7897 12.47C20.8997 11.48 20.9197 10.04 19.9597 8.96002ZM11.6597 3.41002C12.6597 3.32002 13.6097 3.63002 14.3497 4.30002C15.0797 4.96002 15.4897 5.90002 15.4897 6.88002V7.58002H8.50975V7.06002C8.50975 5.28002 9.97975 3.57002 11.6597 3.41002ZM8.41975 13.15H8.40975C7.85975 13.15 7.40975 12.7 7.40975 12.15C7.40975 11.6 7.85975 11.15 8.40975 11.15C8.96975 11.15 9.41975 11.6 9.41975 12.15C9.41975 12.7 8.96975 13.15 8.41975 13.15ZM15.4197 13.15H15.4097C14.8597 13.15 14.4097 12.7 14.4097 12.15C14.4097 11.6 14.8597 11.15 15.4097 11.15C15.9697 11.15 16.4197 11.6 16.4197 12.15C16.4197 12.7 15.9697 13.15 15.4197 13.15Z" fill={color} />
        </svg>

    )
}
export const QuoteIcon = ({ width = 37, height = 27, color = "#171717" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 37 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.928 26.784C7.392 26.784 6.048 26.496 4.896 25.92C3.744 25.28 2.816 24.448 2.112 23.424C1.472 22.4 0.96 21.184 0.576 19.776C0.192 18.368 0 16.896 0 15.36C0 12.224 0.8 9.28 2.4 6.528C4.064 3.776 6.624 1.6 10.08 0L10.944 1.728C9.152 2.56001 7.552 3.776 6.144 5.37601C4.8 6.91201 4 8.54401 3.744 10.272C3.36 11.616 3.328 12.928 3.648 14.208C5.056 12.672 6.944 11.904 9.312 11.904C11.488 11.904 13.28 12.576 14.688 13.92C16.096 15.2 16.8 16.992 16.8 19.296C16.8 21.536 16.064 23.36 14.592 24.768C13.12 26.112 11.232 26.784 8.928 26.784ZM28.704 26.784C27.168 26.784 25.824 26.496 24.672 25.92C23.52 25.28 22.592 24.448 21.888 23.424C21.248 22.4 20.736 21.184 20.352 19.776C19.968 18.368 19.776 16.896 19.776 15.36C19.776 12.224 20.576 9.28 22.176 6.528C23.84 3.776 26.4 1.6 29.856 0L30.72 1.728C28.928 2.56001 27.328 3.776 25.92 5.37601C24.576 6.91201 23.776 8.54401 23.52 10.272C23.136 11.616 23.104 12.928 23.424 14.208C24.832 12.672 26.72 11.904 29.088 11.904C31.264 11.904 33.056 12.576 34.464 13.92C35.872 15.2 36.576 16.992 36.576 19.296C36.576 21.536 35.84 23.36 34.368 24.768C32.896 26.112 31.008 26.784 28.704 26.784Z" fill={color} />
        </svg>
    )
}
export const StarIcon = ({ width = 150, height = 150, color = "#E2DBCB" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 150 148" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M75 0L76.2683 34.2209C77.0442 55.1571 93.8432 71.9475 114.78 72.7127L150 74L114.78 75.2873C93.8432 76.0525 77.0442 92.8429 76.2683 113.779L75 148L73.7317 113.779C72.9558 92.8429 56.1568 76.0525 35.2202 75.2873L0 74L35.2202 72.7127C56.1568 71.9475 72.9558 55.1571 73.7317 34.2209L75 0Z" fill={color} />
            <path d="M112 9.91406L95.9879 40.1843C86.1918 58.7036 92.345 81.644 110.094 92.775L139.952 111.5L108.807 95.0047C90.2925 85.1991 67.3489 91.3405 56.2088 109.084L38 138.086L54.0121 107.816C63.8082 89.2963 57.655 66.3559 39.906 55.2249L10.0481 36.4999L41.1933 52.9952C59.7075 62.8008 82.6511 56.6594 93.7912 38.916L112 9.91406Z" fill={color} />
            <path d="M139.086 37L110.084 55.2088C92.3406 66.3489 86.1991 89.2924 96.0047 107.807L112.5 138.952L93.7751 109.094C82.6441 91.345 59.7037 85.1918 41.1844 94.9879L10.9141 111L39.9161 92.7912C57.6594 81.6511 63.8008 58.7075 53.9953 40.1933L37.5 9.04809L56.2249 38.906C67.3559 56.655 90.2963 62.8082 108.816 53.0121L139.086 37Z" fill={color} />
        </svg>
    )
}
export const CloseIcon = ({ width = 24, height = 24, color = "#292D32" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5L18.9991 18.9991" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.00094 18.9991L19 5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
export const DownIcon = ({ width = 24, height = 24, color = "#171717" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 10L12 15L7 10" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    )
}
export const RightArrowIcon = ({ width = 20, height = 20, color = "#171717" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33301 13.3333L11.6663 9.99996L8.33301 6.66663" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
export const StarRateIcon = ({ width = 17, height = 18, color = "#F4C700" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.72499 2.98612L10.9717 5.47946C11.1417 5.82654 11.595 6.15946 11.9775 6.22321L14.2371 6.59862C15.6821 6.83946 16.0221 7.88779 14.9808 8.92196L13.2242 10.6786C12.9267 10.9761 12.7637 11.5499 12.8558 11.9607L13.3587 14.1353C13.7554 15.8565 12.8417 16.5224 11.3187 15.6228L9.20082 14.369C8.81832 14.1424 8.1879 14.1424 7.79832 14.369L5.6804 15.6228C4.16457 16.5224 3.24374 15.8495 3.6404 14.1353L4.14332 11.9607C4.2354 11.5499 4.07249 10.9761 3.77499 10.6786L2.01832 8.92196C0.984153 7.88779 1.31707 6.83946 2.76207 6.59862L5.02165 6.22321C5.39707 6.15946 5.8504 5.82654 6.0204 5.47946L7.26707 2.98612C7.94707 1.63321 9.05207 1.63321 9.72499 2.98612Z" fill={color} />
        </svg>

    )
}