import React, { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import YouDo from '../Components/Pages/YouDo'
import GetStarted from '../Components/Pages/GetStarted'
import HeadTitle from '../Components/Pages/HeadTitle'
import { NavLink } from 'react-router-dom'

const Services = () => {
    return (
        <Fragment>
            <HeadTitle text={"Just the list of things <br class='hidden sm:block' /> we love to do and how <br /> we do it."} />

            <YouDo />

            <section>
                <Container>

                    <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-4 md:grid-rows-2 gap-4 justify-between">
                        <div>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>01</h5>
                            <h2 className='font-medium text__48 mb-2'>The hub of your creator business</h2>
                            <p className='text__16'>GrowUp is a creator marketing platform with powerful features to help you optimize your most important asset—your audience.</p>
                            <NavLink to="/pricing" className='font-medium text__16 text-Mblue my-12 inline-block'>Start For Free</NavLink>
                            <img src="./../images/du (4).png" alt="" />
                        </div>
                        <div className='md:mt-[8rem]'>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>02</h5>
                            <h2 className='font-medium text__48 mb-2'>Built for creators, by creators</h2>
                            <p className='text__16'>We are a team of creators just like you. And because we’re working our own side-hustles and creative projects, we see your challenges and know your struggles. Most importantly, we believe in your potential and impact, which is why we build features exclusively for creators.</p>
                            <NavLink to="/pricing" className='font-medium text__16 text-Mblue my-12 inline-block'>Start For Free</NavLink>
                            <img src="./../images/du (3).png" alt="" />
                        </div>
                        <div>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>03</h5>
                            <h2 className='font-medium text__48 mb-2'>Grow your audience and reach</h2>
                            <p className='text__16'>Growth is all about experimentation and iterations. We make that process quick and flexible for you to spin up the pages you need to deliver all your important content and convert new audiences.</p>
                            <NavLink to="/pricing" className='font-medium text__16 text-Mblue my-12 inline-block'>Start For Free</NavLink>
                            <img src="./../images/du (1).png" alt="" />
                        </div>
                        <div className='md:mt-[8rem]'>
                            <h5 className='font-medium text__32 text-[#D4D4D4] mb-3'>04</h5>
                            <h2 className='font-medium text__48 mb-2'>Stay connected with your audience</h2>
                            <p className='text__16'>They want to hear from you, and you want to understand what they want. Our audience management system gives you the most flexible way to tag, segment, and organize your audience based on their interests and behavior.</p>
                            <NavLink to="/pricing" className='font-medium text__16 text-Mblue my-12 inline-block'>Start For Free</NavLink>
                            <img src="./../images/du (2).png" alt="" />
                        </div>
                    </div>

                </Container>
            </section>

            <GetStarted />
        </Fragment>
    )
}

export default Services
