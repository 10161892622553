import React, { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import PriceList from '../Components/Pages/PriceList'
import GetStarted from '../Components/Pages/GetStarted'

const Pricing = () => {
    const dataPricetList = [
        {
            label: "Create landing pages",
            free: "8",
            creator: "Unlimited",
            creatorPro: "Unlimited",
        },
        {
            label: "Create link pages",
            free: "8",
            creator: "Unlimited",
            creatorPro: "Unlimited",
        },
        {
            label: "Create email opt-in forms",
            free: "8",
            creator: "Unlimited",
            creatorPro: "Unlimited",
        },
        {
            label: "Landing page and form templates",
            free: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creator: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creatorPro: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
        },
        {
            label: "Customizable domain",
            free: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creator: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creatorPro: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
        },
        {
            label: "Unlimited traffic",
            free: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creator: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creatorPro: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
        },
        {
            label: "Deliver opt-in incentives",
            free: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creator: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
            creatorPro: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
        },
        {
            label: "List growth reporting",
            free: <img className='mx-auto' src="./../images/close-outline 1.svg" alt="" />,
            creator: <img className='mx-auto' src="./../images/close-outline 1.svg" alt="" />,
            creatorPro: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
        },
        {
            label: "Facebook custom audiences",
            free: <img className='mx-auto' src="./../images/close-outline 1.svg" alt="" />,
            creator: <img className='mx-auto' src="./../images/close-outline 1.svg" alt="" />,
            creatorPro: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
        },
        {
            label: "Newsletter referral system",
            free: <img className='mx-auto' src="./../images/close-outline 1.svg" alt="" />,
            creator: <img className='mx-auto' src="./../images/close-outline 1.svg" alt="" />,
            creatorPro: <img className='mx-auto' src="./../images/checkmark-outline 1.svg" alt="" />,
        },
    ]


    const FaqData = [
        {
            title: "How is the pricing structure for your SaaS email marketing platform determined?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Are there any additional costs or hidden fees associated with your SaaS email marketing platform?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Can I customize my pricing plan based on my specific requirements?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Do you offer a free trial or a demo of your SaaS email marketing platform?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Are there any long-term contracts or can I cancel at any time?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
        {
            title: "Are there any limitations on the number of email campaigns or automation workflows I can create?",
            desc: "Our pricing structure is based on factors such as the number of subscribers you have, the volume of emails you send per month, and the features you require. We offer tiered pricing plans to accommodate businesses of different sizes and needs. Please visit our Pricing page or contact our sales team for more detailed information."
        },
    ]

    const [toogleAccordion, settoogleAccordion] = useState(-1)

    const toogelAccordionWrap = (e) => {
        if (e == toogleAccordion) {
            settoogleAccordion(-1)
        } else {
            settoogleAccordion(e)
        }
    }


    const [toogeStep, settoogeStep] = useState("Free")
    return (
        <Fragment>
            <section>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='font-medium text__48 mb-3'>Plans for every stage of your <br /> creator business</h2>
                        <p className='text__18 font-medium mb-6'>How many email subscribers do you have?</p>

                        <div className="flex justify-center items-center gap-2">
                            <h5 className='font-medium text__18'>Monthly</h5>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                className='checkLarge'
                            />
                            <h5 className='font-medium text__18 text-Mblue'>Yearly</h5>
                            <h5 className='font-medium text__14 ml-3 text-Mblue'>Save up to 40%</h5>
                        </div>
                    </div>

                    <PriceList />


                    <div className="mt-12">
                        <Row className='py-3 hidden md:flex'>
                            <Col md={6}></Col>
                            <Col md={2} className='text-center'>
                                <h5 className='font-medium text__24 mb-3'>Free</h5>
                                <h2 className='font-medium text__48'>$0<span className='text__18 mb-2'>/month</span></h2>
                            </Col>
                            <Col md={2} className='text-center'>
                                <h5 className='font-medium text__24 mb-3'>Creator</h5>
                                <h2 className='font-medium text__48'>$9<span className='text__18 mb-2'>/month</span></h2>
                            </Col>
                            <Col md={2} className='text-center'>
                                <h5 className='font-medium text__24 mb-3'>Creator Pro</h5>
                                <h2 className='font-medium text__48'>$25<span className='text__18 mb-2'>/month</span></h2>
                            </Col>
                        </Row>

                        <div className="grid grid-cols-3 grid-rows-1 text-center mb-3">
                            <div onClick={() => settoogeStep("Free")} className={"w-full font-medium text__16 py-3 " + (toogeStep == "Free" ? "border-b border-solid !border-Mblue text-Mblue" : "")}>
                                Free
                            </div>
                            <div onClick={() => settoogeStep("Creator")} className={"w-full font-medium text__16 py-3 " + (toogeStep == "Creator" ? "border-b border-solid !border-Mblue text-Mblue" : "")}>
                                Creator
                            </div>
                            <div onClick={() => settoogeStep("Creator Pro")} className={"w-full font-medium text__16 py-3 " + (toogeStep == "Creator Pro" ? "border-b border-solid !border-Mblue text-Mblue" : "")}>
                                Creator Pro
                            </div>
                        </div>

                        {
                            dataPricetList.map((obj, i) => {
                                return <Row className={'py-3 ' + ((i + 1) % 2 != 0 ? "bg-[#FAFAFA]" : "")}>
                                    <Col className='col-8 my-auto' md={6}>
                                        <h5 className='font-medium text__20'>{obj.label}</h5>
                                    </Col>
                                    <Col  md={2} className={'col-4 text-center my-auto ' + (toogeStep == "Free" ? "block":"hidden md:block")}>
                                        <div className='font-medium text__20'>{obj.free}</div>
                                    </Col>
                                    <Col  md={2} className={'col-4 text-center my-auto ' + (toogeStep == "Creator" ? "block":"hidden md:block")}>
                                        <div className='font-medium text__20'>{obj.creator}</div>
                                    </Col>
                                    <Col  md={2} className={'col-4 text-center my-auto ' + (toogeStep == "Creator Pro" ? "block":"hidden md:block")}>
                                        <div className='font-medium text__20'>{obj.creatorPro}</div>
                                    </Col>
                                </Row>
                            })
                        }
                    </div>
                </Container>
            </section>


            <section>
                <Container>
                    <div className="mb-10">
                        <h2 className='font-medium text__48 mb-2'>Frequently asked questions</h2>
                        <p className='text__16'>If you have questions that are not listed here, send them to <br className='hidden sm:block' /> us via <span className='text-Mblue'>email.</span></p>
                    </div>

                    {
                        FaqData.map((obj, i) => {
                            return <div>
                                <div className="py-3 flex justify-between items-center cursor-pointer " onClick={() => toogelAccordionWrap(i)}>
                                    <h5 className='font-medium text__24'>{obj.title}</h5>
                                    <div className={"arrowPlus relative w-[24px] h-[24px] " + (toogleAccordion == i ? "active" : "")}>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>

                                <div className={toogleAccordion == i ? "block" : "hidden"}>
                                    <p className='text__18'>{obj.desc}</p>
                                </div>
                            </div>
                        })
                    }
                </Container>
            </section>

            <GetStarted />
        </Fragment>
    )
}

export default Pricing
