import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Pagination, Row } from 'react-bootstrap'
import ContactForm from '../Components/Pages/ContactForm'

const Contact = () => {
    return (
        <Fragment>
            <ContactForm />
        </Fragment>
    )
}

export default Contact
