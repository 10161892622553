import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import VerificationInput from 'react-verification-input'

const ResetPassword = () => {
    return (
        <Fragment>
            <img src="./../../images/ghhfgh.svg" className='mb-[2rem]' alt="" />

            <div className="mb-6">
                <h4 className='font-medium text__24 mb-2'>Reset Password</h4>
                <p className='text__16 text-[#525252]'>Recover your account password</p>
            </div>

            <div className="mb-3 wrapVerification">
                <VerificationInput length={5} classNames={{
                    container: "wrap",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                }} />
            </div>

            <div className="text-center">
                <NavLink to="/auth/new-password" className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Next</NavLink>
                <a href="#!" className='inline-block font-medium text__16 mt-3 text-Mblue'>Resend</a>
            </div>
        </Fragment>
    )
}

export default ResetPassword
