import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const GetStarted = ({ title, sub, btnTitle, btnLink }) => {
    return (
        <section className='bg-Mblue overflow-hidden relative'>
            <img src="./../images/Group 33483.png" className='absolute w-[9rem] sm:w-[12rem] md:w-[20rem] xl:w-auto left-4 top-0' alt="" />
            <img src="./../images/Group 33484.png" className='absolute w-[9rem] sm:w-[12rem] md:w-[20rem] xl:w-auto right-4 bottom-0' alt="" />
            <Container className='relative z-[2]'>
                <div className="text-center text-Mwhite">
                    <h2 className='font-medium text__48 mb-2'>{title ? title : "Get Started Today"}</h2>
                    <p className='text__16 mb-8'>{sub ? sub : "And it’s free. Two things everyone loves."}</p>
                    <NavLink to={btnLink ? btnLink : "/pricing"} className='inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>{btnTitle ? btnTitle : "Start For Free"}</NavLink>
                </div>
            </Container>
        </section>
    )
}

export default GetStarted
