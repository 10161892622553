import React from 'react'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DownIcon } from '../Icon/Icon'
import { NavLink } from 'react-router-dom'

const PriceList = () => {
    const [ToogleAccordion, setToogleAccordion] = useState("Creator")

    const setToogleAccordionWrap = (e) => {
        if (e == ToogleAccordion) {
            setToogleAccordion("")
        } else {
            setToogleAccordion(e)
        }
    }
    return (
        <Row>
            <Col className='mb-4 md:!mb-0' md={4}>
                <div className="p-4 bg-[#FAFAFA] rounded-xl text-center">
                    <h5 className='font-medium text__24 mb-3'>Essential Plan</h5>
                    <h2 className='font-medium text__48'>$2,100<span className='text__18 mb-2'>/mo</span></h2>
                    <p className='text__16'>Ideal for newcomers to tech integration.</p>

                    <NavLink to="/plans-details" className='my-4 inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>Try It Now</NavLink>

                    <div className='inline-block h-[1px] w-full bg-Mblack opacity-[12%] mb-4 hidden md:block'></div>

                    <div className="md:hidden flex items-center justify-between py-3 my-3 border-t border-b border-solid !border-[#1717171f] cursor-pointer" onClick={() => setToogleAccordionWrap("Essential")}>
                        <h5 className='font-medium text__14'>See plan summary</h5>
                        <img src="./../images/majesticons_chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion === "Essential Plan" ? "rotate-180" : "")} alt="" />
                    </div>

                    <div className={ToogleAccordion === "Essential" ? "block" : "hidden md:block"}>
                        <div className="flex justify-center text-center w-full flex-wrap gap-4 font-medium text__18">
                            <p className='w-full opacity-70'>Develop 5 basic chatbots</p>
                            <p className='w-full opacity-70'>Automate 3 Zapier/Integromat processes</p>
                            <p className='w-full opacity-70'>48-hour standard email support</p>
                            <p className='w-full opacity-70'>AI analytics for one project</p>
                            <p className='w-full opacity-70'>Basic IVR for customer calls</p>
                            <p className='w-full opacity-70'>Free migration from another tool</p>
                            <p className='w-full opacity-70'>Chatbot powered by GPT 4 Turbo</p>
                            <p className='w-full opacity-70'>Chatbot powered by Anthropic</p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className='mb-4 md:!mb-0' md={4}>
                <div className="p-4 bg-Mblue rounded-xl text-center text-Mwhite">
                    <h5 className='font-medium text__24 mb-3'>Professional Plan</h5>
                    <h2 className='font-medium text__48'>$3,500<span className='text__18 mb-2'>/mo</span></h2>
                    <p className='text__16'>For businesses scaling tech capabilities.</p>

                    <NavLink to="/plans-details" className='my-4 inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>Try It Now</NavLink>

                    <div className='inline-block h-[1px] w-full bg-Mblack opacity-[12%] mb-4 hidden md:block'></div>

                    <div className="md:hidden flex items-center justify-between py-3 my-3 border-t border-b border-solid !border-[#ffffff1f] cursor-pointer" onClick={() => setToogleAccordionWrap("Professional")}>
                        <h5 className='font-medium text__14'>See plan summary</h5>
                        <div className={"transition-all duration-500 " + (ToogleAccordion === "Professional Plan" ? "rotate-180" : "")}>
                            <DownIcon color={"white"} />
                        </div>
                    </div>

                    <div className={ToogleAccordion === "Professional" ? "block" : "hidden md:block"}>
                        <div className="flex justify-center text-center w-full flex-wrap gap-4 font-medium text__18">
                            <p className='w-full'>Develop 10 advanced chatbots</p>
                            <p className='w-full'>Automate 10 premium processes</p>
                            <p className='w-full'>24-hour priority email support</p>
                            <p className='w-full'>AI analytics for five projects</p>
                            <p className='w-full'>Custom IVR for engagement</p>
                            <p className='w-full'>Support for full-stack app development</p>
                            <p className='w-full'>Code troubleshooting and fixes</p>
                            <p className='w-full'>Subscriber scoring</p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className='mb-4 md:!mb-0' md={4}>
                <div className="p-4 bg-[#FAFAFA] rounded-xl text-center">
                    <h5 className='font-medium text__24 mb-3'>Enterprise</h5>
                    <h2 className='font-medium text__48'>$4,800<span className='text__18 mb-2'>/mo</span></h2>
                    <p className='text__16'>Complete tech solutions for major operations.</p>

                    <NavLink to="/plans-details" className='my-4 inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>Try It Now</NavLink>

                    <div className='inline-block h-[1px] w-full bg-Mblack opacity-[12%] mb-4 hidden md:block'></div>

                    <div className="md:hidden flex items-center justify-between py-3 my-3 border-t border-b border-solid !border-[#1717171f] cursor-pointer" onClick={() => setToogleAccordionWrap("Enterprise")}>
                        <h5 className='font-medium text__14'>See plan summary</h5>
                        <img src="./../images/majesticons_chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion === "Enterprise" ? "rotate-180" : "")} alt="" />
                    </div>

                    <div className={ToogleAccordion === "Enterprise" ? "block" : "hidden md:block"}>
                        <div className="flex justify-center text-center w-full flex-wrap gap-4 font-medium text__18">
                            <p className='w-full opacity-70'>Unlimited custom chatbot development</p>
                            <p className='w-full opacity-70'>Full-scale business process automation</p>
                            <p className='w-full opacity-70'>Dedicated manager and priority support</p>
                            <p className='w-full opacity-70'>Advanced custom AI analytics</p>
                            <p className='w-full opacity-70'>End-to-end app development</p>
                            <p className='w-full opacity-70'>Continuous code optimization service</p>
                            <p className='w-full opacity-70'>Complete IVR and SMS chatbot integration</p>
                            <p className='w-full opacity-70'>Monthly tech strategy sessions</p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default PriceList
