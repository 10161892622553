import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import GetStarted from '../Components/Pages/GetStarted'
const About = () => {

    const dataTeam = [
        {
            img: "./../images/av (6).png",
            name: "Nolan Korsgaard",
            job: "Founder & Owners",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
        {
            img: "./../images/av (7).png",
            name: "Jaylon Vaccaro",
            job: "Founder & Owners",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
        {
            img: "./../images/av (8).png",
            name: "Ryan Gouse",
            job: "Founder & Owners",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
        {
            img: "./../images/av (1).png",
            name: "Cooper Saris",
            job: "Engineering Manager",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
        {
            img: "./../images/av (2).png",
            name: "Marcus Philips",
            job: "Software Engineer",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
        {
            img: "./../images/av (3).png",
            name: "Brandon Herwitz",
            job: "Tech Engineering Manager",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
        {
            img: "./../images/av (4).png",
            name: "Martin Calzoni",
            job: "Product Designer",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
        {
            img: "./../images/av (5).png",
            name: "Miracle Siphron",
            job: "Content",
            facebook: "#!",
            linkedin: "#!",
            twitter: "#!",
        },
    ]
    return (
        <Fragment>
            <HeadTitle text={"About Us"} />


            <section>
                <Container>
                    <Row>
                        <Col className='mb-4 md:my-auto' md={6}>
                            <img src="./../images/dfsdfsdf.png" alt="" />
                        </Col>
                        <Col className='my-auto' md={6}>
                            <h2 className='font-medium text__48 mb-2'>Dedicated to helping businesses achieve success</h2>
                            <p className='text__16 mb-3'>Our mission is to provide you with a comprehensive suite of tools and features that simplify the entire email marketing process, from campaign creation to performance analysis. Whether you're a small startup or a large enterprise, our platform is designed to meet your specific needs and drive results.</p>
                            <p className='text__16'>With our user-friendly drag-and-drop editor, you can effortlessly create stunning email campaigns that capture your brand's essence and engage your audience. We offer a wide selection of customizable templates, allowing you to create professional-looking emails without any coding expertise.</p>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section>
                <Container>

                    <Row>
                        <Col className='md:!order-1 order-2' md={6}>
                            <h2 className='font-medium text__48 mb-10'>Anything you need to do with your audience</h2>

                            <div className="flex flex-wrap gap-6">

                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA]">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/favorite-chart.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='font-medium text__24 mb-2'>Grow</h5>
                                        <p className='text__18'>Build systems to grow your audience and reach across channels</p>
                                    </div>
                                </div>
                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA]">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/share.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='font-medium text__24 mb-2'>Connect</h5>
                                        <p className='text__18'>Segment your audience and email with deliverability you can rely on</p>
                                    </div>
                                </div>
                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA]">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/grammerly.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='font-medium text__24 mb-2'>Automate</h5>
                                        <p className='text__18'>Save time by putting your best campaigns on auto-pilot</p>
                                    </div>
                                </div>
                                <div className="flex items-center w-full gap-3 p-4 md:p-6 border border-solid border-[#E5E5E5] rounded-[24px] hover:bg-[#FAFAFA]">
                                    <div className="w-[48px] lg:w-[64px] h-[48px] lg:h-[64px] rounded-full flex items-center justify-center bg-Mblue shrink-0">
                                        <img src="./../images/dollar-circle.svg" alt="" />
                                    </div>
                                    <div className="">
                                        <h5 className='font-medium text__24 mb-2'>Monetize</h5>
                                        <p className='text__18'>Sell digital products and integrate additional platforms for sales insights</p>
                                    </div>
                                </div>

                            </div>

                        </Col>
                        <Col className='order-1 md:!order-2 text-right mb-4 md:mb-0 md:mt-auto' md={6}>
                            <img src="./../images/sfsdfgsdgd.png" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='font-medium text__48 mb-2'>Grow your product with the <br className='hidden md:bloxk' /> right team</h2>
                        <p className='text__16'>The team behind GrowUp are spread around the globe, but come together for one purpose, <br className='hidden md:bloxk' /> to make email easier and more robust for professional creators like you.</p>
                    </div>

                    <Row className='gap-y-4'>
                        {
                            dataTeam.map((obj) => {
                                return <Col className='col-6' md={4} lg={3}>
                                    <div className="p-4 text-center">
                                        <img src={obj.img} className='mx-auto w-[88px] lg:w-[136px] w-[88px] lg:h-[136px] rounded-full object-cover' alt="" />

                                        <div className="my-4">
                                            <h4 className='font-medium text__20 mb-2'>{obj.name}</h4>
                                            <p className='text__14 text-[#737373]'>{obj.job}</p>
                                        </div>

                                        <div className="flex items-center justify-center gap-3">
                                            <a href={obj.facebook}>
                                                <img src="./../images/ss (3).svg" alt="" />
                                            </a>
                                            <a href={obj.linkedin}>
                                                <img src="./../images/ss (1).svg" alt="" />
                                            </a>
                                            <a href={obj.twitter}>
                                                <img src="./../images/ss (2).svg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>

            <GetStarted title="Join our fast growing team" sub="Join our team and help us build the future of data collection." btnTitle="See Open Roles" btnLink="/join-team" />


            <section>
                <Container>
                    <h2 className='font-medium text__48 mb-10 text-center'>Anything you need to do with your audience</h2>

                    <div className="flex justify-center items-center flex-wrap gap-x-2 md:gap-x-4">
                        <img src="./../images/brr (11).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (1).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (2).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (3).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (4).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (5).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (6).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (7).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (8).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (9).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                        <img src="./../images/brr (10).png" className='w-[5rem] sm:w-[9rem] lg:w-[12rem] xl:w-auto' alt="" />
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default About
