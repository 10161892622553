import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const PlansDetails = () => {
    return (
        <section>
            <Container>
                <div className="mb-10">
                    <h2 className='font-medium text__48 mb-3'>Creator Plan</h2>
                    <p className='text__18 font-medium mb-6'>We consider when determining the pricing plan for creator plan, such as the scope of services provided, the number of consultants involved, and the duration of the consultation offered. Here are some of the generally used pricing plans options</p>
                </div>

                <Row>
                    <Col className='mb-4 md:mb-0' md={8}>
                        <h4 className='font-medium text__24 mb-3'>Will you get!</h4>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="flex flex-wrap gap-3">
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Unlimited landing pages</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Unlimited forms</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Hours response time</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Unlimited broadcasts</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Audience tagging and segmentation</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Sell digital products & subscriptions</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Community support</span>
                                </div>
                            </div>
                            <div className="flex flex-wrap gap-3">
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Free migration from another tool</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Automated email sequences</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Visual automation builders</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Third-party integrations</span>
                                </div>
                                <div className="flex items-center gap-2 w-full">
                                    <img src="./../images/Icon.svg" alt="" />
                                    <span className='font-medium text__20'>Additional team members</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="w-full p-4 rounded-[20px] border border-solid border-[#E5E5E5]">
                            <h3 className='font-medium text__24 mb-2'>Get your plan today</h3>
                            <p className='text__16 mb-4'>$108 billed annually for up to 300 subscribers</p>

                            <Form.Select aria-label="Default select example" className='w-full h-[52px] rounded-full border border-solid border-[#E5E5E5] outline-none focus:shadow-none'>
                                <option>Select Plan</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>

                            <div className="mt-4 mb-4">
                                <p className='text__14 mb-2'>Total Price</p>
                                <h3 className='font-medium text__32'>$9 USD</h3>
                            </div>

                            
                            <NavLink to="/payment" className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Subscribe Now</NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PlansDetails
