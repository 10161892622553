import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const JoinForm = () => {
    const [fileUpload, setfileUpload] = useState(null)
    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        console.log(fileUploaded)
        setfileUpload(fileUploaded)
    };
    return (
        <Fragment>
            <section>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='font-medium text__48 mb-2'>Join our fast growing team</h2>
                        <p className='text__16'>Join our team and help us build the future of data collection.</p>
                    </div>

                    <Row>
                        <Col className='col-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                            </Form.Group>
                        </Col>
                        <Col className='col-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Last Name" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Email</Form.Label>
                        <Form.Control type="text" placeholder="Email address" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Position</Form.Label>
                        <Form.Select aria-label="Default select example" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]'>
                            <option>Select Position</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className='font-normal text__14 text-[#A3A3A3]'>CV Resume</Form.Label>
                        <div className="relative">
                            <input ref={hiddenFileInput}
                                onChange={handleChange} type="file" className='absolute -z-[1] opacity-0' />
                            <div onClick={handleClick}>
                                <Form.Control type="text" placeholder={fileUpload ? fileUpload.name : "Drop our resume here or Browse"} className='font-medium cursor-pointer text__14 !bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:!bg-[#FAFAFA]' disabled />
                            </div>
                            <img src="./../images/Clip, Attachment.svg" className='cursor-pointer absolute right-4 top-1/2 -translate-y-1/2' onClick={handleClick} alt="" />
                        </div>
                    </Form.Group>

                    <a href='#!' className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite !py-[15px] !px-[28px] bg-Mblue !border-Mblue btnClass'>Submit</a>


                </Container>
            </section>
        </Fragment>
    )
}

export default JoinForm
