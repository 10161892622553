import React, { Fragment, useState } from 'react'
import { Accordion, Carousel, Col, Container, Form, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { CarouselBrand } from '../Components/Carouse;l/CarouselBrand'
import PriceList from '../Components/Pages/PriceList'
import YouDo from '../Components/Pages/YouDo'
import GetStarted from '../Components/Pages/GetStarted'

const Homepage = () => {

    const dataUsedCreator = [
        {
            title: "Startups",
            desc: "Fueling startup growth with scalable tech solutions. Our AI-driven automation and development services help you stay agile and competitive."
        },
        {
            title: "Small Businesses",
            desc: "Streamline your small business operations with our intelligent automation services, freeing you up to focus on growth and customer engagement."
        },
        {
            title: "E-commerce Platforms",
            desc: "Supercharge your online store with our smart tech integrations. From customer service bots to streamlined checkouts, we've got your back."
        },
        {
            title: "Educational Institutions",
            desc: "Modernizing education with AI and custom software solutions. Automate administrative tasks and enhance learning experiences with our support."
        },
    ]

    const [ToogleAccordion, setToogleAccordion] = useState("Coaches")

    const setToogleAccordionWrap = (e) => {
        if (e === ToogleAccordion) {
            setToogleAccordion("")
        } else {
            setToogleAccordion(e)
        }
    }
    return (
        <Fragment>
            {/* start:hero */}
            <section className='pt-8 pb-0 md:!pb-[80px] bg-Mblue relative overflow-hidden'>
                <Container className='relative z-[2]'>
                    <Row>
                        <Col md={6}>
                            <h1 className='font-medium text__64 text-Mwhite mb-4'>Simple Tech Solutions, Big Business Impact</h1>
                            <p className='text__16 text-Mwhite mb-8'>Experience the ease of technology that works for you, driving substantial  <br className='hidden lg:block' /> business growth and competitive success with straightforward solutions.</p>

                            <NavLink to="/pricing" className='inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgreen !border-Mgreen btnClass'>Try It Now</NavLink>

                            <div className="mt-4 grid grid-rows-3 gap-2">
                                <div className="flex items-center gap-2">
                                    <img src="./../images/check.svg" alt="" />
                                    <span className='font-medium text__20 text-Mwhite'>First month 50% off</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <img src="./../images/check.svg" alt="" />
                                    <span className='font-medium text__20 text-Mwhite'>Easy Sign-up</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <img src="./../images/check.svg" alt="" />
                                    <span className='font-medium text__20 text-Mwhite'>Cancel anytime</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <img src="./../images/Group 33482.png" className='mt-6 md:mt-0 ml-auto md:absolute md:right-0 md:bottom-0 xl:bottom-auto xl:top-4 w-[30rem] lg:w-[34rem] xl:w-auto' alt="" />
            </section>
            {/* end:hero */}

            <YouDo />

            <section>
                <Container>

                    <Row>
                        <Col className='mb-4 md:mb-0' md={6}>
                            <div className="mb-10">
                                <h2 className='font-medium text__48 mb-2'>Empowering Diverse Business Needs</h2>
                                <p className='text__16'>Find out how our specialized AI and automation solutions cater to different business segments, driving efficiency and innovation.</p>
                            </div>

                            <div className="flex flex-wrap gap-3">
                                {
                                    dataUsedCreator.map((obj) => {
                                        return <div className={"p-4 rounded-2xl w-full cursor-pointer " + (ToogleAccordion === obj.title ? "bg-[#FAFAFA]" : "border border-solid border-[#F5F5F5]")} onClick={() => setToogleAccordionWrap(obj.title)}>
                                            <div className="flex items-center justify-between">
                                                <h5 className='font-medium text__20'>{obj.title}</h5>
                                                <img src="./../images/majesticons_chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion === obj.title ? "rotate-180" : "")} alt="" />
                                            </div>
                                            <div className={"py-2 " + (ToogleAccordion === obj.title ? "block" : "hidden")}>
                                                <p className='text__16'>{obj.desc}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>

                        </Col>
                        <Col md={6} className='text-right mt-auto'>
                            <img src="./../images/img (1).png" alt="" />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col className='my-auto md:!order-1 order-2' md={6}>
                            <img src="./../images/fhdhfh.png" alt="" />
                        </Col>
                        <Col className='mb-4 md:my-auto order-1 md:!order-2' md={6}>
                            <h2 className='font-medium text__48 mb-2'>Tech-Driven Solutions to Skyrocket Your Revenue</h2>
                            <p className='text__16 mb-8'>Imagine this: Your e-commerce platform, once juggling orders manually, now has a custom AI chatbot that handles inquiries instantly, 24/7. Behind the scenes, automated workflows synchronize inventory and shipping, cutting costs. The result? Happy customers, soaring sales, and a thriving business.</p>
                            <NavLink to="/pricing" className='inline-block cursor-pointer font-medium text__16 text-Mwhite bg-Mblue !border-Mblue btnClass'>Try It Now</NavLink>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section>
                <Container>
                    <div className="text-center mb-10">
                        <h2 className='font-medium text__48 mb-2'>Plans for every stage of your <br /> business needs</h2>
                    </div>

                    <PriceList />
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col className='my-auto md:!order-1 order-2' md={6}>
                            <img src="./../images/content.png" alt="" />
                        </Col>
                        <Col className='mb-4 md:my-auto order-1 md:!order-2' md={6}>
                            <h2 className='font-medium text__48 mb-2'>Integrate Effortlessly, Enhance Productivity, Maximize Growth</h2>
                            <p className='text__16'>Imagine a CRM system where leads update themselves and follow-ups are autonomously scheduled. Our tailored automation ensures every client interaction is an opportunity for growth. Your team can concentrate on closing deals and building relationships, propelled by a workflow that's as ambitious as your business goals.</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='bg-[#FAFAFA] overflow-hidden'>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='font-medium text__48 mb-2'>Powered By The Best Tools In The Market</h2>
                        <p className='text__16'>Leverage cutting-edge tools to revolutionize your business operations and strategy.</p>
                    </div>

                    <div className="flex justify-center flex-wrap gap-5 lg:gap-10 mb-10">
                        <img src="./../images/p-tool1.png" className='w-[9rem] lg:w-[10rem] h-auto object-contain' alt="" />
                        <img src="./../images/p-tool2.png" className='w-[9rem] lg:w-[10rem] h-auto object-contain' alt="" />
                        <img src="./../images/p-tool3.png" className='w-[9rem] lg:w-[10rem] h-auto object-contain' alt="" />
                        <img src="./../images/p-tool4.png" className='w-[9rem] lg:w-[10rem] h-auto object-contain' alt="" />
                        <img src="./../images/p-tool5.png" className='w-[9rem] lg:w-[10rem] h-auto object-contain' alt="" />
                    </div>


                </Container>
                <CarouselBrand />
            </section>

           <GetStarted />

        </Fragment >
    )
}

export default Homepage
