import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const ForgotPassowrd = () => {
    return (
        <Fragment>
            <img src="./../../images/ghhfgh.svg" className='mb-[2rem]' alt="" />

            <div className="mb-6">
                <h4 className='font-medium text__24 mb-2'>Forgot your password?</h4>
                <p className='text__16 text-[#525252]'>We'll send you an email with a link to reset your password.</p>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Email<span className='text-[#ED544E]'>*</span></Form.Label>
                <Form.Control type="email" placeholder="E.g; james@company.com" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
            </Form.Group>

            <NavLink to="/auth/riset-password" className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite !py-[15px] bg-Mblue !border-Mblue btnClass w-full'>Send me a reset link</NavLink>
        </Fragment>
    )
}

export default ForgotPassowrd
