import React from 'react'
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect } from 'react';

import DefaultLayout from './Layouts/DefaultLayout';

import Homepage from './Pages/Homepage';
import Pricing from './Pages/Pricing';
import Services from './Pages/Services';
import PlansDetails from './Pages/PlansDetails';
import Payment from './Pages/Payment';
import About from './Pages/About';
import JoinTeam from './Pages/JoinTeam';
import JoinForm from './Pages/JoinForm';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import Faq from './Pages/Faq';


// auth
import AuthLayout from './Layouts/AuthLayout';
import Login from './Pages/Auth/Login';
import ForgotPassowrd from './Pages/Auth/ForgotPassowrd';
import ResetPassword from './Pages/Auth/ResetPassword';
import NewPassword from './Pages/Auth/NewPassword';

const App = () => {
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return (
    <Routes>
      <Route path="auth" element={<AuthLayout />}>
        <Route index path="login" element={<Login />} />
        <Route index path="forgot-password" element={<ForgotPassowrd />} />
        <Route index path="riset-password" element={<ResetPassword />} />
        <Route index path="new-password" element={<NewPassword />} />
      </Route>
      <Route path="" element={<DefaultLayout />}>
        <Route index element={<Homepage />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="services" element={<Services />} />
        <Route path="plans-details" element={<PlansDetails />} />
        <Route path="payment" element={<Payment />} />
        <Route path="about" element={<About />} />
        <Route path="join-team" element={<JoinTeam />} />
        <Route path="join-form" element={<JoinForm />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/detail" element={<BlogDetail />} />
        <Route path="contact" element={<Contact />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="faq" element={<Faq />} />
      </Route>
    </Routes>
  )
}

export default App
