import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

const Payment = () => {
    return (
        <section>
            <Container>
                <div className="mb-10">
                    <h2 className='font-medium text__48 mb-3'>Creator Plan</h2>
                    <p className='text__18 font-medium mb-6'>We consider when determining the pricing plan for creator plan, such as the scope of services provided, the number of consultants involved, and the duration of the consultation offered. Here are some of the generally used pricing plans options</p>
                </div>

                <Row>
                    <Col className='mb-4 md:mb-0' md={7} lg={8}>
                        <h4 className='font-medium text__24 mb-3'>Pay with</h4>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Cardholder Name</Form.Label>
                            <Form.Control type="email" placeholder="Ex:  San Andreas" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Card Number</Form.Label>
                            <Form.Control type="email" placeholder="Ex:  31111 1111 1111 1111" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                        </Form.Group>

                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Postal Code</Form.Label>
                                    <Form.Control type="email" placeholder="123456" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='font-normal text__14 text-[#A3A3A3]'>Expiration</Form.Label>
                                    <Form.Control type="email" placeholder="MM/YY" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='font-normal text__14 text-[#A3A3A3]'>CCV</Form.Label>
                                    <Form.Control type="email" placeholder="123" className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]' />
                                </Form.Group>
                            </Col>
                        </Row>

                        <a href='#!' className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite bg-Mblue !border-Mblue btnClass w-full md:w-auto'>Confirm and pay</a>

                    </Col>
                    <Col md={5} lg={4}>
                        <div className="w-full p-4 rounded-[20px] border border-solid border-[#E5E5E5]">
                            <h3 className='font-medium text__24 mb-2'>Get your plan today</h3>
                            <p className='text__16 mb-4'>$108 billed annually for up to 300 subscribers</p>

                            <Form.Select aria-label="Default select example" className='w-full h-[52px] rounded-full border border-solid border-[#E5E5E5] outline-none focus:shadow-none'>
                                <option>Select Plan</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>

                            <div className="mt-4 mb-3 flex items-center justify-between">
                                <p className='text__16'>Total Price</p>
                                <h3 className='font-semibold text__18'>$9 USD</h3>
                            </div>
                            <div className="mt-4 flex items-center justify-between">
                                <p className='text__16'>Tax Platform</p>
                                <h3 className='font-semibold text__18 text-Mblue'>Free</h3>
                            </div>
                            <hr className='my-3 borer-[#1C1C1E1F]' />
                            <div className="mt-4 mb-3 flex items-center justify-between">
                                <p className='text__16'>Total Amount</p>
                                <h3 className='font-semibold text__18'>$9 USD</h3>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Payment
